import React, { FunctionComponent, ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { GatsbyImage } from 'gatsby-plugin-image';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { UnstyledLink } from '../atm.unstyled-link/unstyled-link.styled';
import { CompanyCardStyled } from './company-card.styled';

interface ICompanyCardProps {
  title: string;
  linkTo: string;
  image: ReactElement;
}

export const CompanyCard: FunctionComponent<ICompanyCardProps> = (props) => {
  return (
    <CompanyCardStyled elevation={3}>
      <UnstyledLink to={props.linkTo}>{props.image}</UnstyledLink>
    </CompanyCardStyled>
  );
};
