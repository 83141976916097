import { Box, Grid, Typography } from '@material-ui/core';

import PageLayout from '../components/org.page-layout/page-layout.component';
import { ProductCard } from '../components/mol.product-card/product-card.component';
import React from 'react';
import { Spacing } from '../components/obj.constants/constants';
import { useStaticQuery, graphql, PageProps } from 'gatsby';
import { convertToSlug } from '../utils/convertToSlug';
import { GatsbyImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';
import { CompanyCard } from '../components/mol.company-card/company-card.component';
import { LineSpacer } from '../components/atm.line-spacer/line-spacer.styled';

const CompaniesPage = (props: PageProps) => {
  const pageData = (props.data as any).markdownRemark as any;
  const pageMetadata = pageData.frontmatter;
  const edges = (props.data as any).allFile.edges;
  const companies = edges.map((edge: any) => ({
    title: edge.node.childMarkdownRemark.frontmatter.title,
    linkTo: convertToSlug(edge.node.childMarkdownRemark.frontmatter.title),
    image: (
      <GatsbyImage
        image={
          edge.node.childMarkdownRemark.frontmatter.companyCardImage
            .childImageSharp.gatsbyImageData
        }
        alt={`${edge.node.childMarkdownRemark.frontmatter.title} logo image`}
        objectFit="contain"
      />
    ),
  }));
  return (
    <>
      <Seo title={pageMetadata.companiesPageTitle} />
      <Box
        paddingTop={Spacing.XLarge}
        paddingBottom={Spacing.XLarge}
        paddingLeft={Spacing.Large}
        paddingRight={Spacing.Large}
      >
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={1} lg={2} />
          <Grid item xs={10} lg={8}>
            <Typography variant="h1">
              {pageMetadata.companiesPageTitle}
            </Typography>
          </Grid>
          <Grid item xs={1} lg={2} />
          <LineSpacer height={Spacing.XLarge} />
          {companies.map((company: any) => (
            <Grid item xs={12} sm={4} md={3} key={company.title}>
              <CompanyCard
                title={company.title}
                linkTo={company.linkTo}
                image={company.image}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export const query = graphql`
  query companyQuery {
    allFile(filter: { relativeDirectory: { eq: "companies" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              companyCardImage {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 2.66)
                }
              }
            }
            html
          }
        }
      }
    }
    markdownRemark(frontmatter: { fileName: { eq: "companiesPage" } }) {
      frontmatter {
        companiesPageTitle
      }
    }
  }
`;

export default CompaniesPage;
